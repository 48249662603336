import './codeForm.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import CodeInput from '../code-input';
import CodeErrorLabel from '../code-label';

class CodeForm extends PureComponent {
  onCodeChanged = (inputCode) => {
    const { onCodeChange } = this.props;

    if (onCodeChange) {
      onCodeChange(inputCode);
    }
  };

  onSubmitHandler = (evt) => {
    evt.preventDefault();

    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit();
    }
  };

  render() {
    const { errorId, errorMessage, codeEnabled } = this.props;

    return (
      <form className='code-form' action='/' onSubmit={this.onSubmitHandler} ref='CodeForm'>
        <CodeInput onCodeChanged={this.onCodeChanged} enabled={codeEnabled} />
        <CodeErrorLabel errorId={errorId} errorMessage={errorMessage} />
        <Button label={'ENTER'} onClick={this.onSubmitHandler} disabled={!codeEnabled} />
      </form>
    );
  }
}

CodeForm.defaultProps = {
  codeEnabled: true
};

CodeForm.propTypes = {
  errorId: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  codeEnabled: PropTypes.bool,
  onCodeChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default CodeForm;
