import * as ACTIONS from './actionTypes';

const updateAuth = (auth) => {
  return {
    type: ACTIONS.AT_APP_UPDATE_AUTH,
    auth
  };
};

export const hasAuthenticated = (auth) => {
  return (dispatch) => {
    dispatch(updateAuth(auth));
  };
};
