/*
  CSS
*/
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './animations.css';

/*
  React/Redux App
*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Store from './redux-store';
import * as serviceWorker from './serviceWorker';

/*
  AWS Configuration
*/
import Amplify from 'aws-amplify';
import AWSConfig from './aws-config';

/*
  App
*/
import AppContainer from './containers/app';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AWSConfig.cognito.REGION,
    userPoolId: AWSConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: AWSConfig.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'university',
        endpoint: AWSConfig.apiGateway.URL,
        region: AWSConfig.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(
  <Provider store={Store}>
      <AppContainer />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
