import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppContext } from '../../libs/contextLib';
import * as Actions from './actions';
import CodeView from '../../components/code-view';
import CodeForm from '../../components/code-form';
import { history } from '../../libs/historyLib';
import { queryParameter } from '../../libs/queryLib';

class LoginContainer extends Component {
  componentDidUpdate(previousProps) {
    if (this.props.success) {
      this.context.hasAuthenticated(true);
      const redirect = queryParameter('redirect');
      history.push(redirect);
    }
  }

  render() {
    const { errorId, errorMessage, codeEnabled } = this.props;

    return (
      <CodeView>
        <CodeForm
          errorId={errorId}
          errorMessage={errorMessage}
          codeEnabled={codeEnabled}
          onCodeChange={this.props.onUpdateCode}
          onSubmit={this.props.onSubmitCode} />
      </CodeView>
    );
  }
}

LoginContainer.contextType = AppContext;

LoginContainer.propTypes = {
  success: PropTypes.bool.isRequired,
  errorId: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  inputCode: PropTypes.string.isRequired,
  codeEnabled: PropTypes.bool.isRequired,
  onUpdateCode: PropTypes.func.isRequired,
  onSubmitCode: PropTypes.func.isRequired
};

const mapStateToProps = state => ({...state.login});
const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(LoginContainer);
