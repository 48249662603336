import * as ACTIONS from './actionTypes';

const initialState = {
  success: false,
  errorId: 0,
  errorMessage: '',
  inputCode: '',
  codeEnabled: true
};

export default (state = initialState, action) => {
    switch(action.type) {
      case ACTIONS.AT_CODE_UPDATE_CODE:
      {
        return {
          ...state,
          inputCode: action.code
        };
      }
      case ACTIONS.AT_CODE_ERROR:
      {
        return {
          ...state,
          errorId: action.id,
          errorMessage: action.message
        };
      }
      case ACTIONS.AT_CODE_SUCCESS:
      {
        return {
          ...state,
          success: true
        };
      }
      case ACTIONS.AT_CODE_DISABLE:
      {
        return {
          ...state,
          codeEnabled: false
        };
      }
      case ACTIONS.AT_CODE_ENABLE:
      {
        return {
          ...state,
          codeEnabled: true
        };
      }
      default:
        return state;
    }
};
