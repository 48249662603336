import * as ACTIONS from './actionTypes';

const initialState = {
  info: {},
  fetchError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.AT_VIDEO_FETCH_INFO_ERROR:
    {
      return {
        ...state,
        fetchError: true
      };
    }
    case ACTIONS.AT_VIDEO_RECEIVED_INFO:
    {
      return {
        ...state,
        info: action.info
      };
    }
    default:
      return state;
  }
};
