import './codeView.css';
import React from 'react';
import Section, { SectionBlock } from '../section';
import Banner from '../banner';

import juniorBanner from '../../images/junior-banner.jpg';

const CodeView = (props) => {
  return (
    <Banner image={juniorBanner} fitViewport>
      <div className='code-view'>
        <div className='code-view_header'>
          <i className='fas fa-graduation-cap fa-6x code-view_logo' />
          <h2 className='code-view_title'>University</h2>
        </div>
        <div className='code-view_body'>
          {props.children}
        </div>
      </div>
    </Banner>
  );
}

export default CodeView;
