import * as ACTIONS from './actionTypes';

const initialState = {
  videos: [],
  error: false
};

export default (state = initialState, action) => {
    switch(action.type) {
      case ACTIONS.AT_UNI_ERROR:
      {
        return {
          ...state,
          error: true
        };
      }
      case ACTIONS.AT_UNI_RECEIVED_VIDEOS:
      {
        return {
          ...state,
          videos: action.videos
        };
      }
      default:
        return state;
    }
};
