export default {
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_kR7hPyaDs',
    APP_CLIENT_ID: '4rk1td0ccskq0dv80pqub2c0lo'
  },
  apiGateway: {
    REGION: 'eu-west-2',
    URL: 'https://dev.api.goshinju-jitsu.co.uk/web'
  }
};
