import './codeInput.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CodeInput extends PureComponent {
  onInputChanged = (event) => {
    event.preventDefault();

    const { onCodeChanged } = this.props;
    if (onCodeChanged) {
      onCodeChanged(this.codeInputRef.value);
    }
  };

  render() {
    return (
      <input
        className='codeinput'
        ref={(inputObj) => this.codeInputRef = inputObj}
        type='password'
        placeholder='enter code...'
        maxLength='32'
        disabled={!this.props.enabled}
        onChange={this.onInputChanged} />
    );
  }
}

CodeInput.defaultProps = {
  enabled: true
};

CodeInput.propTypes = {
  enabled: PropTypes.bool,
  onCodeChanged: PropTypes.func
};

export default CodeInput;
