import './library.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from '../../components/video-player';

class Library extends PureComponent {
  render() {
    const { videoDetails } = this.props;

    return (
      <ul className='libary-list'>
        {videoDetails && videoDetails.map((item, index) => (
          <li key={index}>
            <a href={`#/uni/video/${item.videoId}`}>{item.title}</a>
          </li>
        ))}
      </ul>
    );
  }
}

Library.propTypes = {
  videoDetails: PropTypes.array
};

export default Library;
