import { Auth, API } from 'aws-amplify';
import * as ACTIONS from './actionTypes';

const onError = () => {
  return {
    type: ACTIONS.AT_UNI_ERROR
  };
};

const onReceivedVideos = (videos) => {
  return {
    type: ACTIONS.AT_UNI_RECEIVED_VIDEOS,
    videos
  };
};

export const getVideos = () => {
  return async (dispatch) => {
    try {
      const session = await Auth.currentSession();
      console.log(session.getIdToken().getJwtToken())
      const response = await API.get('university', '/university/videos', {
        headers: {
          Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
        }
      });
      dispatch(onReceivedVideos(response.results));
    } catch (err) {
      dispatch(onError());
    }
  };
};
