import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from './actions';

import Page from '../../components/page';
import Section, { SectionBlock } from '../../components/section';
import Libray from '../../components/library';

class UniversityContainer extends Component {
  componentDidMount() {
    this.props.getVideos();
  }

  render() {
    return (
      <Page title='UNIVERSITY | Goshin Ju-Jitsu'>
        <Section>
          <SectionBlock>
            <Libray videoDetails={this.props.videos} />
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

UniversityContainer.propTypes = {
  videos: PropTypes.array.isRequired,
  error: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({...state.university});
const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(UniversityContainer);
