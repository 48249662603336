/*
  React/Redux App
*/
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from './actions';

/*
  Libraries
*/
import { AppContext } from '../../libs/contextLib';
import { history } from '../../libs/historyLib';

/*
  Routes
*/
import { AuthRoute, UnauthRoute } from '../../components/routes';

/*
  App Views/Pages
*/
import NavBar from '../../components/navbar';
import Footer from '../../components/footer';
import Unknown from '../../components/unknown';
import HomeContainer from '../../containers/home';
import About from '../../components/about';
import Junior from '../../components/junior';
import Adult from '../../components/adult';
import Private from '../../components/private';
import FindDojo from '../../components/find-dojo';
import Gallery from '../../components/gallery';
import Terms from '../../components/terms';
import Instructors from '../../components/instructors';
import UniContainer from '../../containers/university';
import LoginContainer from '../../containers/login';
import BannerAlertContainer from '../../containers/banner-alert';
import VideoContainer from '../../containers/video';


class AppContainer extends Component {
  render() {
    const { isAuth, hasAuthenticated } = this.props;

    return (
      <ConnectedRouter history={history}>
        <AppContext.Provider value={{isAuth, hasAuthenticated}}>
          <NavBar />
          <BannerAlertContainer />
            <Switch>
              <Route exact path='/' component={HomeContainer} />
              <Route exact path='/about' component={About} />
              <Route exact path='/junior' component={Junior} />
              <Route exact path='/adult' component={Adult} />
              <Route exact path='/private' component={Private} />
              <Route exact path='/find-dojo' component={FindDojo} />
              <Route exact path='/instructors' component={Instructors} />
              <Route exact path='/gallery' component={Gallery} />
              <AuthRoute exact path='/uni'><UniContainer/></AuthRoute>
              <AuthRoute exact path='/uni/video/:videoId'><VideoContainer/></AuthRoute>
              <UnauthRoute exact path='/login' component={LoginContainer} />
              <Route exact path='/terms' component={Terms} />
              <Route component={Unknown} />
            </Switch>
          <Footer />
        </AppContext.Provider>
      </ConnectedRouter>
    );
  }
}

AppContainer.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  hasAuthenticated: PropTypes.func.isRequired
};

const mapStateToProps = state => ({...state.app});
const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(AppContainer);
