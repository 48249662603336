import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppContext } from '../../libs/contextLib';

export default function AuthRoute({ children, ...rest }) {
  const pathname = window.location.hash.replace(/\#/, '');
  const { isAuth } = useAppContext();

  return (
    <Route {...rest}>
      { isAuth ?  (
        children
      ) : (
        <Redirect to={`/login?redirect=${pathname}`} />
      )}
    </Route>
  );
}
