import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppContext } from '../../libs/contextLib';
import { queryParameter } from '../../libs/queryLib';

export default function UnauthRoute({ children, ...rest }) {
  const { isAuth }  = useAppContext();
  const redirect = queryParameter('redirect');

  return (
    <Route {...rest}>
      { isAuth ?  (
        <Redirect to={(redirect === '' || redirect === null) ? '/' : redirect} />
      ) : (
        children
      )}
    </Route>
  );
}
