import './button.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.button = React.createRef();
  }

  onClick = (event) => {
    event.preventDefault();
    this.button.current.blur();
    
    const { onClick, disabled } = this.props;

    if (onClick && !disabled) {
      this.props.onClick(event);
    }
  };

  render() {
    const { label, disabled } = this.props;
    return (
      <div
        ref={this.button}
        className='button'
        onClick={this.onClick}
        disabled={disabled}>
        {label}
      </div>
    );
  }
}

Button.defaultProps = {
  disabled: false
};

Button.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
