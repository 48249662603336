import './codeLabel.css';
import React from 'react';
import PropTypes from 'prop-types';

const CodeErrorLabel = (props) => {
  const { errorId, errorMessage } = props;

  const hasError = (errorId > 0 && errorMessage);
  const displayId = `(0x${errorId.toString(16).padStart(2, '0')})`;
  const displayMessage = `${errorMessage} ${displayId}`;

  return (
    <div className='code-error-label'>
      <p>{hasError && displayMessage}</p>
    </div>
  );
};

CodeErrorLabel.propTypes = {
  errorId: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default CodeErrorLabel;
