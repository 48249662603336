import { Auth, API } from 'aws-amplify';
import * as ACTIONS from './actionTypes';

const onFetchError = () => {
  return {
    type: ACTIONS.AT_VIDEO_FETCH_INFO_ERROR
  };
};

const onReceivedInfo = (info) => {
  return {
    type: ACTIONS.AT_VIDEO_RECEIVED_INFO,
    info
  };
};

export const getVideoInfo = (videoId) => {
  return async (dispatch) => {
    let response;

    try {
      const session = await Auth.currentSession();
      response = await API.get('university', `/university/video/${videoId}`, {
        headers: {
          Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
        }
      });
    } catch (err) {
      dispatch(onFetchError());
    }

    if (response && response.status) {
      dispatch(onReceivedInfo(response.results));
    }
  };
};
