import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { history } from './libs/historyLib';
import reducer from './redux-reducer';

const initialState = {};
const middleware = [
  thunk,
  routerMiddleware(history)
];

const composedMiddleware = compose(
  applyMiddleware(...middleware)
);

const Store = createStore(
  reducer,
  initialState,
  composedMiddleware
);

export default Store;
