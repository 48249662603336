import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from './actions';

import Page from '../../components/page';
import Section, { SectionBlock } from '../../components/section';

class VideoContainer extends Component {
  componentDidMount() {
    const videoId = this.getVideoId();
    
    if (videoId) {
      this.props.getVideoInfo(videoId);
    }
  }

  getVideoId = () => {
    const splitPath = window.location.hash.split('/');

    return (splitPath.length == 4) ? splitPath[3] : '';
  };

  renderVideo = (videoInfo) => {
    return (
      <VideoPlayer
        videoUrl={videoInfo.endpoints.HLS}
        thumbnailUrl={videoInfo.thumbnailURL} />
    );
  };

  renderError = () => {
    return (
      <p>{'Error'}</p>
    );
  };

  render() {
    const { videoInfo, fetchError } = this.props;
    const title = videoInfo ? videoInfo.title : undefined;

    return (
      <Page title='Video | Goshin Ju-Jitsu'>
        <Section>
          <SectionBlock header={title}>
            {videoInfo && this.renderVideo(videoInfo)}
            {fetchError && this.renderError()}
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

VideoContainer.propTypes = {
  info: PropTypes.object.isRequired,
  fetchError: PropTypes.bool.isRequired,
  getVideoInfo: PropTypes.func.isRequired
}

const mapStateToProps = state => ({...state.video});
const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(VideoContainer);
