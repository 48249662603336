import { Auth } from 'aws-amplify';
import * as ACTIONS from './actionTypes';
import { AppContext } from '../../libs/contextLib';

function onError(id, message) {
  return {
    type: ACTIONS.AT_CODE_ERROR,
    id,
    message
  };
}

function onSuccess() {
  return {
    type: ACTIONS.AT_CODE_SUCCESS
  }
}

function disableCodeEntry() {
  return {
    type: ACTIONS.AT_CODE_DISABLE
  };
}

function enableCodeEntry() {
  return {
    type: ACTIONS.AT_CODE_ENABLE
  };
}

async function onAuthorizationRequest(dispatch, code) {
  // Disallow user entering another code
  dispatch(disableCodeEntry());

  try {
    const user = await Auth.signIn('test_user', code);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(onError(0x01, 'contact admin'));
    } else {
      dispatch(onSuccess());
    }
  } catch(err) {
    if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      dispatch(onError(0x02, 'contact admin'));
    } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      dispatch(onError(0x04, 'contact admin'));
    } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
      dispatch(onError(0x08, 'code invalid'));
    } else if (err.code === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      dispatch(onError(0x10, 'contact admin'));
    } else if (err.code === 'InvalidParameterException') {
      // This error happens when an invalid parameter is sent
      // Will occur when the code is blank or null
      dispatch(onError(0x20, 'code invalid or contact admin'));
    } else {
      console.log(err)
      dispatch(onError(0x40, 'unknown error, contact admin'));
    }

    // Finally allow user to enter a new code
    dispatch(enableCodeEntry());
  }
}

export const onUpdateCode = (code) => {
  return {
    type: ACTIONS.AT_CODE_UPDATE_CODE,
    code
  };
}

export const onSubmitCode = () => {
  return async (dispatch, getState) => {
    const { inputCode } = getState().login;

    if (inputCode.length == 0) {
      dispatch(onError(0x100, 'code cannot be blank'));
    }
    else {
      await onAuthorizationRequest(dispatch, inputCode);
    }
  }
}
