import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { AppReducer } from './containers/app';
import { HomeReducer } from './containers/home';
import { DojoMapReducer } from './containers/dojo-map';
import { AlertReducer } from './containers/banner-alert';
import { UniversityReducer } from './containers/university';
import { LoginReducer } from './containers/login';
import { VideoReducer } from './containers/video';

export default combineReducers({
  routing: routerReducer,
  app: AppReducer,
  home: HomeReducer,
  map: DojoMapReducer,
  alert: AlertReducer,
  university: UniversityReducer,
  login: LoginReducer,
  video: VideoReducer
});
