import './navbar.css';
import React, { Component } from 'react';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class NavBar extends Component {
  render() {
    return (
      <Navbar fixedTop collapseOnSelect fluid>
        <Navbar.Header>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <div className='brand-logo' />
              <div>
                <div className='brand-title'>GOSHIN</div>
                <div className='brand-subtitle'>JU-JITSU</div>
              </div>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <NavDropdown eventKey={1} title='TRAINING' id='training-dropdown'>
              <LinkContainer to='/junior'>
                <MenuItem eventKey={1.1}>JUNIOR</MenuItem>
              </LinkContainer>
              <LinkContainer to='/adult'>
                <MenuItem eventKey={1.2}>ADULT</MenuItem>
              </LinkContainer>
              <LinkContainer to='/private'>
                <MenuItem eventKey={1.3}>PRIVATE</MenuItem>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to='/find-dojo'>
              <NavItem eventKey={2}>FIND DOJO</NavItem>
            </LinkContainer>
            <LinkContainer to='/about'>
              <NavItem eventKey={3}>ABOUT</NavItem>
            </LinkContainer>
            <LinkContainer to='/instructors'>
              <NavItem eventKey={3}>INSTRUCTORS</NavItem>
            </LinkContainer>
            <LinkContainer to='/gallery'>
              <NavItem eventKey={4}>GALLERY</NavItem>
            </LinkContainer>
            <LinkContainer to='/uni'>
              <NavItem eventKey={5}>UNIVERSITY</NavItem>
            </LinkContainer>
          </Nav>
          <Nav pullRight>
            <NavItem
              eventKey={5}
              href='https://www.twitter.com/goshin_jujitsu'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='fab fa-twitter fa-2x social-links' />
            </NavItem>
            <NavItem
              eventKey={6}
              href='https://www.facebook.com/pages/Goshin-Ju-Jitsu-Association-GB-london/432276686841053?ref=ts&fref=ts'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='fab fa-facebook-square fa-2x social-links' />
            </NavItem>
            <NavItem
              eventKey={7}
              href='https://www.instagram.com/goshinjujitsu1/'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='fab fa-instagram fa-2x social-links' />
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
