import * as ACTIONS from './actionTypes';

const initialState = {
  isAuth: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.AT_APP_UPDATE_AUTH:
    {
      return {
        ...state,
        isAuth: action.auth
      };
    }
    default:
      return state;
  }
};
